@mixin svg($background-color, $checkmark-color: white) {
  border: none;
  background-color: $background-color;
  fill: $checkmark-color;
}
.sv-checkbox__decorator {
  border-radius: 2px;
}
.sv-checkbox__svg {
  border: 3px solid $border-color;
  border-radius: 2px;
  fill: transparent;
}
.sv-checkbox--allowhover:hover {
  .sv-checkbox__svg {
    @include svg($main-hover-color);
  }
}
.sv-checkbox--checked .sv-checkbox__svg {
  @include svg($main-color);
}
.sv-checkbox--checked.sv-checkbox--disabled .sv-checkbox__svg {
  @include svg($disable-color);
}
.sv-checkbox--disabled .sv-checkbox__svg {
  border: 3px solid $disable-color;
}
