.sv-page__title {
  margin: 0;
  margin-bottom: 1.333em;
  font-size: 1.875em;
  padding-left: 0.293em;
}
.sv-page__description {
  min-height: 2.8em;
  font-size: 1em;
  padding-left: 0.55em;
}
.sv-page__title + .sv-page__description {
  margin-top: -2.8em;
}
