.sv-matrixdynamic__add-btn {
  background-color: $add-button-color;
}
.sv-matrixdynamic__remove-btn {
  background-color: $remove-button-color;
}

.sv-detail-panel__icon {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 13px;
  width: 24px;
  transform: translate(-50%, -50%) rotate(270deg);
}

.sv-detail-panel__icon--expanded {
  transform: translate(-50%, -50%);
}

.sv-detail-panel__icon:before {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%239A9A9A;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='19,6 17,4 10,11 3,4 1,6 10,15 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 18px;
  width: 24px;
}
