.sv-matrix {
  overflow-x: auto;

  .sv-table__cell--header {
    text-align: center;
  }
}
.sv-matrix__label {
  display: inline-block;
  margin: 0;
}
.sv-matrix__cell {
  min-width: 10em;
  text-align: center;
  &:first-child {
    text-align: left;
  }
}
.sv-matrix__text {
  cursor: pointer;
}
.sv-matrix__text--checked {
  color: #ffffff;
  background-color: $main-color;
}
.sv-matrix__text--disabled {
  cursor: default;
  &.sv-matrix__text--checked {
    background-color: $disable-color;
  }
}
.sv-matrix__row--error {
  background-color: $error-background-color;
}
