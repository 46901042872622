.sv-comment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.06em solid $text-border-color;
  border-radius: 0;
  box-sizing: border-box;
  padding: 0.25em 0.87em;
  font-family: inherit;
  font-size: 1em;
  outline: none;
  width: 100%;
}
.sv-comment:focus {
  border-color: $main-color;
}
