.sv-rating {
  color: $text-color;
  padding-bottom: 3px;
  input:focus + .sv-rating__min-text + .sv-rating__item-text,
  input:focus + .sv-rating__item-text {
    outline: 1px solid $main-color;
    outline-offset: 2px;
  }
}

.sv-rating__item {
  position: relative;
  display: inline;
}

.sv-rating__item-text {
  min-width: 2.3125em;
  height: 2.3125em;
  display: inline-block;
  color: $main-hover-color;
  padding: 0 0.3125em;
  border: solid 0.1875em $main-hover-color;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.13;
  cursor: pointer;
  margin: 3px 0;
  margin-right: 0.26em;
  box-sizing: border-box;

  & > span {
    margin-top: 0.44em;
    display: inline-block;
  }

  &:hover {
    background-color: $main-hover-color;
    color: $body-background-color;
  }
}

.sv-rating__item--selected {
  .sv-rating__item-text {
    background-color: $main-color;
    color: $body-background-color;
    border-color: $main-color;

    &:hover {
      background-color: $main-color;
    }
  }
}

.sv-rating__min-text {
  font-size: 1em;
  margin-right: 1.25em;
  cursor: pointer;
}

.sv-rating__max-text {
  font-size: 1em;
  margin-left: 0.87em;
  cursor: pointer;
}

.sv-question--disabled {
  .sv-rating__item-text {
    cursor: default;
    color: $disable-color;
    border-color: $disable-color;
    &:hover {
      background-color: transparent;
    }
  }

  .sv-rating__item--selected {
    .sv-rating__item-text {
      background-color: $disable-color;
      color: $body-background-color;
    }
  }

  .sv-rating__min-text {
    cursor: default;
  }

  .sv-rating__max-text {
    cursor: default;
  }
}
