.sv-multipletext {
  width: 100%;
  table-layout: fixed;
}
.sv-multipletext__item-label {
  display: flex;
  align-items: center;
}
.sv-multipletext__item {
  flex: 1;
}
.sv-multipletext__item-title {
  margin-right: 1em;
  width: 33%;
}
.sv-multipletext__cell:not(:first-child) {
  padding-left: 0.5em;
}
.sv-multipletext__cell:not(:last-child){
  padding-right: 0.5em;
}