.sv-radio__decorator {
  border-radius: 100%;
}
.sv-radio__svg {
  border: 3px solid $border-color;
  border-radius: 100%;
  fill: transparent;
}
.sv-radio--allowhover:hover {
  .sv-radio__svg {
    fill: $border-color;
  }
}
.sv-radio--checked .sv-radio__svg {
  border-color: $radio-checked-color;
  fill: $radio-checked-color;
}
.sv-radio--disabled .sv-radio__svg {
  border-color: $disable-color;
}
.sv-radio--disabled.sv-radio--checked .sv-radio__svg {
  fill: $disable-color;
}
