.sv-btn.sv-action-bar-item,
.sv-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 1.214em;
    color: #ffffff;
    cursor: pointer;
    font-family: inherit;
    font-size: 0.875em;
    font-weight: bold;
    outline: none;
    padding: 0.5em 2.786em 0.6em;
    text-align: left;
}