.sv-text {
  box-sizing: border-box;
  width: 100%;
  height: 2.19em;
  padding: 0.25em 0 0.25em 0.87em;
  border: none;
  border-radius: 0;
  border-bottom: 0.07em solid $text-border-color;
  box-shadow: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1em;
}
.sv-text:focus {
  border-color: $main-color;
  outline: none;
  box-shadow: none;
}
.sv-text:invalid {
  box-shadow: none;
}
.sv-text:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
.sv-text::placeholder {
  opacity: 1;
  color: $text-color;
}
.sv-text:-ms-input-placeholder {
  opacity: 1;
  color: $text-color;
}
.sv-text::-ms-input-placeholder {
  opacity: 1;
  color: $text-color;
}
.sv-text[type="date"] {
  padding-right: 2px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.61em top 50%, 0 0;
  background-size: 0.57em auto, 100%;
}
.sv-text[type="date"]:focus {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
}

.sv-text[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: transparent;
}
.sv-text[type="date"]::-webkit-clear-button {
  display: none;
}
.sv-text[type="date"]::-webkit-inner-spin-button {
  display: none;
}
.sv-text--error {
  color: $error-color;
  border-color: $error-color;
}
.sv-text--error::placeholder {
  color: $error-color;
}
.sv-text--error::-ms-input-placeholder {
  color: $error-color;
}
.sv-text--error::-ms-input-placeholder {
  color: $error-color;
}
