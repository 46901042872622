.sv-table {
  width: 100%;
  background-color: rgba(159, 159, 159, 0.1);
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr:last-child {
      .sv-table__cell {
        padding-bottom: 2.5em;
      }
    }
  }

  tr:first-child {
    .sv-table__cell {
      padding-top: 1.875em;
    }
  }

  td,
  th {
    &:first-child {
      padding-left: 1.875em;
    }
  }
  td,
  th {
    &:last-child {
      padding-right: 1.875em;
    }
  }
}
.sv-table__row--detail {
  background-color: $header-background-color;

  td {
    border-top: 1px solid $text-border-color;
    border-bottom: 1px solid $text-border-color;
    padding: 1em 0;
  }
}

.sv-table__cell {
  padding: 0.9375em 0;
  box-sizing: content-box;
  vertical-align: top;
}
.sv-table__cell:not(:last-child) {
  padding-right: 1em;
}
.sv-table__cell:not(:first-child) {
  padding-left: 1em;
}
.sv-table__cell--header {
  font-family: Raleway;
  font-weight: bold;
  text-align: left;
}
.sv-table__cell--rowText {
  vertical-align: middle;
}
.sv-table__cell--detail {
  text-align: center;
  vertical-align: middle;
  width: 32px;
}
.sv-table__cell--detail-rowtext {
  vertical-align: middle;
}
.sv-table__cell--detail-panel {
  padding-left: 1em;
}
.sv-table__cell--detail-button {
  appearance: none;
  position: relative;
  border: 3px solid $border-color;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
}
.sv-table__empty--rows--section {
  text-align: center;
  vertical-align: middle;
}
.sv-table__empty--rows--text {
  padding: 20px;
}
.sv-table__cell--actions {
  sv-action-bar,
  .sv-action-bar {
    margin-left: 0;
    padding-left: 0;
  }
}
