$primary: var(--primary, #19b394);

$background: var(--background, #fff);
$background-dim: var(--background-dim, #f3f3f3);

$foreground-light: var(--foreground-light, #909090);
//todo: discuss this variable
$foreground: var(--foreground, #161616);
$border-inside: var(--border-inside, rgba(0, 0, 0, 0.16));
$font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$base-unit: var(--base-unit, 8px);

@function calcSize($multiplier) {
  $result: calc(#{$multiplier} * #{$base-unit});
  @return $result;
}

.sv-list {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  background: $background;
  font-family: $font-family;
  list-style-type: none;
}

.sv-list__empty-container {
  width: 100%;
  font-family: $font-family;
  box-sizing: border-box;
  padding: calcSize(6) calcSize(8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-list__empty-text {
  line-height: calcSize(3);
  font-size: calcSize(2);
  font-weight: 400;
  text-align: center;
  color: $foreground-light;
}

.sv-list__item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: calcSize(1) calcSize(8) calcSize(1) calcSize(2);
  color: $foreground;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:focus {
    background-color: $background-dim;
    outline: none;
  }
}
.sv-list__item--with-icon {
  padding-top: calcSize(1.5);
  padding-bottom: calcSize(1.5);
}
.sv-list__item-icon {
  float: left;
  width: calcSize(3);
  height: calcSize(3);
  svg {
    display: block;
  }
  use {
    fill: $foreground-light;
  }
  margin-right: calcSize(2);
}

.sv-list__item--selected {
  background-color: $primary;
  color: $background;

  &:hover,
  &:focus {
    background-color: $primary;
    color: $primary-foreground;
  }
  .sv-list__item-icon use {
    fill: $background;
  }
}
.sv-list__item.sv-list__item--disabled {
  cursor: default;
  color: $foreground-light;
}

.sv-list__item span {
  white-space: nowrap;
}

.sv-list__container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.sv-list__filter {
  border-bottom: 1px solid $border-inside;
  background: $background;
  padding-bottom: calcSize(1);
}

.sv-list__filter-icon {
  display: block;
  position: absolute;
  top: calcSize(1.5);
  left: calcSize(2);
  .sv-svg-icon {
    width: calcSize(3);
    height: calcSize(3);
    use {
      fill: $foreground-light;
    }
  }
}

.sv-list__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: $background;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-size: 1em;
  color: $foreground;
  padding: calcSize(1.5) calcSize(3) calcSize(1.5) calcSize(7);
  line-height: calcSize(3);
}

.sv-list__input::placeholder {
  color: $foreground-light;
}

.sv-list__input:disabled,
.sv-list__input:disabled::placeholder {
  color: $foreground-light;
}
