.confirm-permission-modal .ant-btn-primary{
  background: #0B2A1B;
  border-color: #0B2A1B;
}
.confirm-permission-modal .ant-btn:hover{
  border-color: #0B2A1B;
  color:#0B2A1B;
}
.confirm-permission-modal .ant-btn-primary:hover{
  color: #ffffff;
}