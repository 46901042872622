.sv-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.57em 100%;
  border: none;
  border-bottom: 0.06em solid #d4d4d4;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding: 0.25em 1.5em 0.25em 0.87em;
  height: 2.19em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sv-dropdown:focus {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231AB394;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E ");
  border-color: $dropdown-border-color;
  outline: none;
}
.sv-dropdown::-ms-expand {
  display: none;
}
.sv-dropdown--error {
  border-color: $error-color;
  color: $error-color;
}
.sv-dropdown--error::placeholder {
  color: $error-color;
}
.sv-dropdown option {
  color: $text-color;
}
.sv-dropdown__value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sv-dropdown_clean-button {
  padding: 3px 12px;
}
.sv-dropdown_clean-button-svg {
  width: 12px;
  height: 12px;
}
