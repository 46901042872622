/* .dailyEventWrapper{
  color: black !important;
} */
.modeWrapper{

  text-align: start !important;
}
.calendarWrapper  > div > div{
background-color: white;
padding: 0px 10px 0px 10px;
}
.awesome-calendar-header {
  background-color: #f0f0f0;
  margin: 10px 0px !important;
}
.calendarHeaderButtons{
margin: 10px 0px 10px 0px !important;
}
.calendarHeader{
  border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: 11px;
    padding: 7px 10px 0px 10px;
    background: white;
}
.calendarHeader h1{
  margin-top: 10px;
  justify-content: center;
    display: flex;
  font-size: 1.5em;
}
.calendarHeader span.thickText{
  color: #98A2B2 !important;
}
.calendarHeader span.thinkText{
  font-weight: 700 !important;
  color: #98A2B2 !important;
}
.calendarDayOfWeek{
  font-weight: 400 !important;
    color: #8F9497 !important;
}
/* .awesome-calendar-header h1{
  font-size: 1em
} */
/* .ant-tabs-content{
  border: none ;
  padding: 20px !important;
  background-color: white !important;
}  */
 .modeButton {
  /* border-radius: 8px !important; */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  margin-left: 8px;
  width: 60px;
  height: 40px;
  color: #8F9497 !important; 
  padding: 10px 10px !important;
  border-radius: 8px;
  border: 1px;
  border-color: #EAECF0;
  background-color: #F9F9FB;
}
.modeButtonActive{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  margin-left: 8px;
  width: 60px;
  height: 40px;
  color: white !important;
  padding: 10px 10px !important;
  border-radius: 8px;
  border: 1px;
  border-color: #0B2A1B;
  background-color: #0B2A1B;
}
.mainDiv {
  /* background-color: white; */
  border: none;
  /* padding: 10px 0px 0px 0px; */
  border-radius: 8px;
  
}
.yearlyMonth {
  float: inherit !important;
  background-color: white !important;
}